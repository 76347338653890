module.exports = [
    {
        path: '/administration',
        name: 'administration',
        component: () => import('./index'),
        children: [
            { path: 'profile', name: 'administration.profile' },
            { path: 'business', name: 'administration.business' },
            { path: 'subscription', name: 'administration.subscription' },
            { path: 'users', name: 'administration.users' }
        ]
    }
]
