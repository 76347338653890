<template>
    <b-datetimepicker v-model="model" icon="calendar-today"
                      v-bind="$attrs"
                      style="min-width: 130px" />
</template>

<script>
    import dayjs from 'dayjs'


    export default {
        name: 'GDateTimePicker',
        props: { value: { type: [ String, Date ], default: '' } },
        computed: {
            model: {
                get() {
                    return this.value ? dayjs(this.value).toDate() : undefined
                },
                set(value) {
                    const output = dayjs(value).toISOString()
                    this.$emit('input', output)
                }
            }
        }
    }
</script>
