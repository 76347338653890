<template>
    <b-autocomplete v-model="model"
                    :data="filteredData"
                    :open-on-focus="openOnFocus"
                    keep-first
                    clearable
                    @input="(e) => $emit('change', e)"
    />
</template>

<script>
    export default {
        name: 'GAutocomplete',
        props: {
            value: { type: [ String, Date ], default: '' },
            data: { type: [ Array ], default: () => [] },
            openOnFocus: { type: Boolean, default: false }
        },
        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            filteredData() {
                if(!this.value)
                    return this.data
                return this.data
                    .filter(d => d && d.toLowerCase().indexOf(this.value.toLowerCase()) !== -1)
            }
        }
    }
</script>
