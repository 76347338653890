module.exports = [
    {
        path: '/',
        name: 'landing-page',
        meta: { auth: false },
        component: () => import('./LandingPage')
    },
    {
        path: '/impressum',
        name: 'impressum',
        meta: { auth: false },
        component: () => import('./Impressum')
    }
]
