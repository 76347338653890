import ApiService from '@/services/api.service'

export const ARCHIVE_ENDPOINT = 'archive/archive/'

const actions = {
    async fetch({ commit }, filters) {
        commit('setError', null)
        commit('setLoading', true)
        let params = {}
        if (filters)
            params = {
                form__type: filters.formType,
                created_at__gte: filters.from,
                created_at__lte: filters.to,
                file_name__icontains: filters.q
            }
        try {
            const response = await ApiService.get(ARCHIVE_ENDPOINT, { params })
            commit('setArchives', response.data)
        } catch (e) {
            commit('setError', e.response.data)
        } finally {
            commit('setLoading', false)
        }
    },
    async getSuggestedFilename(context, formType) {
        const response = await ApiService.get(ARCHIVE_ENDPOINT + 'suggested_filename/', { params: { formType } })
        return response.data
    },
    async save({ commit, dispatch }, { formType, file, fileName }) {
        commit('setError', null)
        commit('setLoading', true)
        const data = { form_type: formType, file, file_name: fileName }
        try {
            await ApiService.post(ARCHIVE_ENDPOINT, data)
            dispatch('fetch')
        } catch (e) {
            commit('setError', e.response.data)
            commit('setLoading', false)
            throw(e)
        }
    },
    async merge({ commit, getters }) {
        commit('setError', null)
        commit('setLoading', true)
        try {
            await ApiService.get(
                `${ ARCHIVE_ENDPOINT }merge_pdf/`,
                { params: { archiveIds: getters.filteredArchiveIds } }
            )
        } catch (e) {
            commit('setError', e.response.data)
            commit('setLoading', false)
            throw(e)
        }
    },
    async remove({ commit, dispatch }, form) {
        commit('setError', null)
        commit('setLoading', true)
        try {
            await ApiService.delete(`${ ARCHIVE_ENDPOINT }${ form.id }/`)
            dispatch('fetch')
        } catch (e) {
            commit('setError', e.response.data)
            commit('setLoading', false)
            throw(e)
        }
    }
}

const state = {
    archives: [],
    loading: false,
    error: null
}

const mutations = {
    setArchives(state, archives) {
        state.archives = archives
    },
    setError(state, message) {
        state.error = message
    },
    setLoading(state, enabled) {
        state.loading = enabled
    }
}

const getters = {
    filteredArchiveIds: state => state.archives.map(a => a.id).join(),
    mergeLink: (state, getters, rootState) => { // only works when BE & FE on same host
        const url = `${ process.env.VUE_APP_API_ROOT }/${ ARCHIVE_ENDPOINT }merge_pdf/`
        const idsParam = `archiveIds=${ getters.filteredArchiveIds }`
        const tokenParam = `token=${ rootState.auth.access }`

        return `${ url }?${ idsParam }&${ tokenParam }`
    }
}

export default {
    namespaced: true,
    actions: actions,
    state: state,
    mutations: mutations,
    getters: getters
}
