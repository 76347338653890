<template>
    <ValidationProvider v-slot="{ errors, touched, validated, invalid }" :name="label || name"
                        :rules="validationRules" slim :vid="vid">
        <b-field :label="label" :message="errors" :expanded="$attrs.expanded"
                 :type="((touched||validated) && invalid) ? 'is-danger' : ''"
                 :style="$attrs.fieldStyle">
            <b-checkbox v-model="model" v-bind="$attrs" :name="name">
                <slot />
            </b-checkbox>
        </b-field>
    </ValidationProvider>
</template>

<script>
    export default {
        name: 'GCheckboxField',
        inheritAttrs: false,
        props: {
            value: { type: Boolean, default: false },

            label: { type: String, default: undefined },
            name: { type: String, required: true },
            required: { type: Boolean, default: false },
            validate: { type: Object, default: () => ({}) },

            vid: { type: String, default: undefined }
        },
        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            validationRules() {
                const rules = {}
                if (this.required)
                    rules.required = { allowFalse: false }

                if (this.validate && Object.entries(this.validate).length)
                    Object.assign(rules, this.validate)

                return rules
            }
        }
    }
</script>
