import ApiService from '@/services/api.service'

const FORM_CATEGORIES_ENDPOINT = 'forms/categories/'

const actions = {
    async fetch({ commit, rootState }, { subtypes, reminders } = {}) {
        commit('setError', null)
        commit('setLoading', true)

        const data = { subtypes, reminders }

        try {
            const response = await ApiService.get(FORM_CATEGORIES_ENDPOINT, { params: data })
            const accessToken = rootState.auth.access
            commit('setFormCategories', { formCategories:response.data, accessToken } )
        } catch (e) {
            commit('setError', e.response.data)
        } finally {
            commit('setLoading', false)
        }
    }
}

const state = {
    formCategories: [],
    loading: false,
    error: null
}

const mutations = {
    setFormCategories(state, { formCategories, accessToken }) {
        // - maybe this could be done in getters, but would then have a useless name like formCategoriesWithLink
        // - because of devServer.proxy, this somehow doesn't render properly during local development
        state.formCategories = formCategories.map(fc => ({
            ...fc,
            pdf: `/api/v1/forms/categories/${fc.type}/?format=pdf&empty=true&token=${accessToken}`
        }))
    },
    setError(state, message) {
        state.error = message
    },
    setLoading(state, enabled) {
        state.loading = enabled
    }
}

const getters = {}

export default {
    namespaced: true,
    actions: actions,
    state: state,
    mutations: mutations,
    getters: getters
}
