<template>
    <b-select v-model="innerValue" v-bind="{ expanded: $attrs.expanded }">
        <option v-for="(option, i) in data" :key="i" :value="option.value">
            {{ option.label }}
        </option>
    </b-select>
</template>

<script>
    export default {
        name: 'GSelect',
        props: {
            value: { type: [ String, Date ], default: '' },
            data: { type: Array, default: () => [] }
        },
        computed: {
            innerValue: {
                get() { return this.value },
                set(value) { this.$emit('input', value) }
            }
        }
    }
</script>
