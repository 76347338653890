// Register components
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
/* eslint-disable camelcase */
import {
    alpha,
    alpha_num,
    between,
    confirmed,
    digits,
    email,
    ext,
    integer,
    length,
    max,
    max_value,
    mimes,
    min,
    min_value,
    numeric,
    regex,
    required
} from 'vee-validate/dist/rules'
/* eslint-enable camelcase */
import i18n from '@/config/i18n'
import Vue from 'vue'

const rules = {
    alpha,
    alpha_num,
    between,
    confirmed,
    digits,
    email,
    ext,
    integer,
    length,
    max_value,
    max,
    mimes,
    min_value,
    min,
    numeric,
    regex,
    required
}
// The following is a list of all default validation messages wrapped with i18n.$t to allow translation
const validationMessages = {
    'alpha': (_, values) => i18n.t('This field may only contain alphabetic characters', values),
    'alpha_num': (_, values) => i18n.t('This field may only contain alpha-numeric characters', values),
    'between': (_, values) => i18n.t('This field must be between {min} and {max}', values),
    'confirmed': (_, values) => i18n.t('This field confirmation does not match', values),
    'digits': (_, values) => i18n.t('This field must be numeric and exactly contain {length} digits', values),
    'email': (_, values) => i18n.t('This field must be a valid email', values),
    'ext': (_, values) => i18n.t('This field is not a valid file', values),
    'integer': (_, values) => i18n.t('This field must be an integer', values),
    'length': (_, values) => i18n.t('This field must be {length} long', values),
    'max_value': (_, values) => i18n.t('This field must be {max} or less', values),
    'max': (_, values) => i18n.t('This field may not be greater than {length} characters', values),
    'mimes': (_, values) => i18n.t('This field must have a valid file type', values),
    'min_value': (_, values) => i18n.t('This field must be {min} or more', values),
    'min': (_, values) => i18n.t('This field must be at least {length} characters', values),
    'numeric': (_, values) => i18n.t('This field may only contain numeric characters', values),
    'regex': (_, values) => i18n.t('This field format is invalid', values),
    'required': (_, values) => i18n.t('This field is required', values)
}

// Install rules
Object.entries(rules).forEach(([ key, rule ]) => extend(key, {
    ...rule,
    message: validationMessages[ key ]
}))

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
