import ApiService from '@/services/api.service'

export const parseJwt = (token) => { // eslint-disable-line import/prefer-default-export
    try {
        return JSON.parse(atob(token.split('.')[ 1 ]))
    } catch (e) {
        return null
    }
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
        const result = reader.result.replace(/^data:.+;base64,/, '') // Remove the data URI prefix
        resolve(result)
    }
    reader.onerror = error => reject(error)

    reader.readAsDataURL(file)
})

export const uuidv4 = () =>
    ([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line no-mixed-operators
        (c ^ crypto.getRandomValues(new Uint8Array(1))[ 0 ] & 15 >> c / 4).toString(16)
    )

/**
 * Debouncing enforces that a function not be called again until a certain amount of time has passed without it being called.
 * @param callback
 * @param wait
 * @param immediate
 * @returns {Function}
 */
export const debounce = function (callback, wait, immediate = false) {
    let timeout = null

    return function () {
        const callNow = immediate && !timeout
        const next = () => callback.apply(this, arguments)

        clearTimeout(timeout)
        timeout = setTimeout(next, wait)

        if (callNow)
            next()
    }
}


export const arrayize = value => Array.isArray(value) ? value : [ value ]

import isEmpty from 'lodash/isEmpty'
import fileSize from 'filesize'

export const removeEmptyProperties = (obj, { ignoreKeys = [] } = {}) =>
    /* Recursively goes through object properties and removes empty (null) ones */
    Object.fromEntries(
        Object.entries(obj)
            .filter(([ k, v ]) => {
                return !isEmpty(v) && !ignoreKeys.includes(k)
            })
            .map(([ k, v ]) => (typeof v === 'object' ? [ k, removeEmptyProperties(v, { ignoreKeys }) ] : [ k, v ]))
            .filter(([ k, v ]) => {
                return !isEmpty(v) && !ignoreKeys.includes(k)
            })
    )

export const filesize = fileSize.partial({ base: 10, round: 0 })

export default class Utils {
    static async download(url) {
        const response = await ApiService.get(
            url,
            { responseType: 'blob' }
        )
        const blob = new Blob([ response.data ], { type: response.data.type })
        const bloburl = URL.createObjectURL(blob)
        window.open(bloburl, '_blank')
    }
}
