module.exports = [
    {
        path: '/forms',
        name: 'forms',
        component: () => import('./Forms'),
        redirect: { name: 'forms.list' },
        children: [
            {
                path: 'list',
                name: 'forms.list',
                component: () => import('./FormList')
            }
        ]
    }
]
