import axios from 'axios'
import store from '@/config/store'
import TokenService from '@/services/storage.service'

axios.interceptors.response.use(
    (response) => response,
    error => {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            return store.dispatch('auth/checkAuth').then(() => {
                originalRequest.headers.Authorization = `Bearer ${ TokenService.getTokens().access }`
                return axios(originalRequest)
            })
        }
        return Promise.reject(error)
    }
)
