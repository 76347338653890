<template>
    <section class="trial-alert-box">
        <template v-if="trial">
            <div class="notification is-warning has-text-centered py-2">
                {{ $t('This is a trial mode.') }}
            </div>
        </template>
    </section>
</template>

<script>
    import ApiService from '../services/api.service'
    import { BUSINESS_ENDPOINT } from '../modules/Administration/store'

    export default {
        name: 'TrialAlertBox',
        data() {
            return {
                company: null,
                isActive: true
            }
        },
        computed: {
            trial() {
                return this.company && this.company.trial
            }
        },
        mounted() {
            this.fetch()
        },
        methods: {
            async fetch() {
                this.company = (await ApiService.get(BUSINESS_ENDPOINT)).data[0]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .trial-alert-box {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    .py-2{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
</style>
