<template>
    <b-datepicker ref="picker" v-model="model"
                  icon="calendar-today"
                  :date-formatter="dateFormatter"
                  style="min-width: 130px"
                  v-bind="$attrs"
                  :nearby-selectable-month-days="true"
    />
</template>

<script>
    import dayjs from 'dayjs'
    import { DATE_FORMAT, DISPLAY_DATE_FORMAT } from '@/constants'

    export default {
        name: 'GDatePicker',
        props: { value: { type: [ String, Date ], default: '' } },
        data: () => ({
            dropdownComponent: null,
            position: null
        }),
        computed: {
            model: {
                get() {
                    if (this.value && this.value.constructor && this.value.constructor.name === 'Date')
                        return dayjs(this.value).toDate()
                    return this.value ? dayjs(this.value, DATE_FORMAT).toDate() : undefined
                },
                set(value) {
                    const output = dayjs(value).format(DATE_FORMAT)
                    this.$emit('input', output)
                }
            }
        },
        methods: { dateFormatter: (value) => dayjs(value).format(DISPLAY_DATE_FORMAT) }
    }
</script>
