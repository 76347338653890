import Vue from 'vue'
import Router from 'vue-router'
import store from '@/config/store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    linkActiveClass: 'is-active',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            meta: { auth: false },
            beforeEnter: (to, from, next) => {
                if(store.getters['auth/isLoggedIn'])
                    // If first login, redirect to settings
                    if (store.getters['auth/isFirstLogin']) {
                        next({ name: 'settings' })
                    }
                    else {
                        next({ name: 'forms' })
                    }
                else
                    next({ name: 'landing-page' })
            }
        },
        ...require(/* webpackChunkName: "landing-page" */ '@/modules/LandingPage/routes'),
        ...require(/* webpackChunkName: "auth" */ '@/modules/Auth/routes'),
        ...require(/* webpackChunkName: "forms" */ '@/modules/Forms/routes'),
        ...require(/* webpackChunkName: "documents" */ '@/modules/Documents/routes'),
        ...require(/* webpackChunkName: "archives" */ '@/modules/Archives/routes'),
        ...require(/* webpackChunkName: "settings" */ '@/modules/Settings/routes'),
        ...require(/* webpackChunkName: "administration" */ '@/modules/Administration/routes')
    ]
})

export default router

// check if authorized
const redirectOnAuthFail = { name: 'login' }
router.beforeEach((to, from, next) => {

    // redirect user to home from login page if user is already logged in
    if (to.name === 'login')
        store.dispatch('auth/checkAuth').then(() => {
            if (store.getters['auth/isLoggedIn'])
                next({ name: 'home' })
        })

    // by default, every route is guarded with auth:true
    // in reality, none routes has auth meta, so it means `auth is undefined`
    if (to.meta.auth !== false && to.name !== redirectOnAuthFail.name)
        store.dispatch('auth/checkAuth').then(() => {
            if (store.getters['auth/isLoggedIn'])
                next()
            else
                next(redirectOnAuthFail)
        })
    else
        next()
})
