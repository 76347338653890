import Vuex, { Store } from 'vuex'
import Vue from 'vue'

import auth from '@/modules/Auth/store'
import forms from '@/modules/Forms/store'
import documents from '@/modules/Documents/store'
import archives from '@/modules/Archives/store'
import settings from '@/modules/Settings/store'
import reminders from '@/modules/Reminders/store'
import registration from '@/modules/Registration/store'
import administration from '@/modules/Administration/store'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Store({
    state: {},
    getters: {},
    modules: { auth, forms, documents, archives, settings, reminders, registration, administration },
    plugins: [ createPersistedState({ storage: window.sessionStorage }) ]
})
