export const DATE_FORMAT = 'YYYY-MM-DD'
export const DISPLAY_DATE_FORMAT = 'DD.MM.YYYY'

export const TIME_FORMAT = 'HH:mm'
export const DURATION_FORMAT = 'HH:mm:ss'
export const DISPLAY_TIME_FORMAT = 'HH:mm'

export const MAX_UPLOAD_FILE_SIZE = 20 * 1024 * 1024

export const DEFAULT_LANG = 'de'
export const LANGUAGE_CODES = {
    german: 'de',
    french: 'fr',
    english: 'en'
}
