module.exports = [
    {
        path: '/settings',
        name: 'settings',
        component: () => import('./Settings'),
        redirect: { name: 'settings.list' },
        children: [
            {
                path: 'list',
                name: 'settings.list',
                component: () => import('./SettingsList')
            },
            {
                path: 'create/:section',
                name: 'settings.create',
                component: () => import('./SettingsForm')
            },
            {
                path: 'update/:section/:id',
                name: 'settings.update',
                component: () => import('./SettingsForm')
            }
        ]
    }
]
