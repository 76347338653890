import ApiService from '@/services/api.service'
import i18n from '@/config/i18n'

export default {
    state: { subscriptionTypes: [] },
    getters: {
        subscriptionOptions: state => state.subscriptionTypes.map(record => {
            record.$label = i18n.t(record.name)
            return record
        })
    },
    actions: {
        async fetchSubscriptionTypes({ commit }) {
            const response = await ApiService.get('/companies/subscription_type/')
            commit('saveSubscriptionTypes', response.data)
        },
        getSubscriptionTypeForCategory({ getters }, category) {
            return getters.subscriptionOptions.find(option => option.categories.split(',').includes(category))
        }
    },
    mutations: {
        saveSubscriptionTypes(state, values) {
            state.subscriptionTypes = values
        }
    },
    namespaced: true
}
