import axios from 'axios'
import TokenService from './storage.service'

const ApiService = {

    init(baseURL) {
        axios.defaults.baseURL = baseURL
    },

    setDefaultHeader(header, value) {
        axios.defaults.headers.common[ header ] = value
    },

    removeDefaultHeader(header) {
        delete axios.defaults.headers.common[ header ]
    },

    enableAuth() {
        this.setDefaultHeader('Authorization', `Bearer ${ TokenService.getTokens().access }`)
    },

    disableAuth() {
        this.removeDefaultHeader('Authorization')
    },

    get(resource, config) {
        return axios.get(resource, config)
    },

    post(resource, data) {
        return axios.post(resource, data)
    },

    put(resource, data) {
        return axios.put(resource, data)
    },

    patch(resource, data) {
        return axios.patch(resource, data)
    },

    delete(resource) {
        return axios.delete(resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     * */
    customRequest(data) {
        return axios(data)
    }
}

export default ApiService
