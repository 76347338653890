<template>
    <div
        class="columns column is-half is-multiline margin-right-less margin-left-less margin-top-less">
        <b-field class="column is-narrow is-marginless is-paddingless" style="align-items: center">
            <b-radio-button
                :value="value === true ? true : undefined"
                :native-value="true"
                type="is-success"
                @click.native.stop.prevent="toggle(true)">
                <b-icon icon="check" />
            </b-radio-button>
            <b-radio-button
                :value="value === false ? false : undefined"
                :native-value="false"
                type="is-danger"
                @click.native.stop.prevent="toggle(false)">
                <b-icon icon="close" />
            </b-radio-button>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'GNullableBoolean',
        props: {
            value: {
                type: Boolean,
                required: false
            }
        },
        methods: {
            toggle(value) {
                if (this.value === value)
                    this.$emit('input', undefined)
                else
                    this.$emit('input', value)

                window.blur()
            }
        }
    }
</script>

<style lang="scss" scoped>
.columns /deep/ .b-radio.button {
    font-size: .9rem;
    color: #666;

    &.is-focused {
        border-color: transparent !important;
    }

    > span {
        font-weight: normal;
    }

    &.is-success, &.is-danger {
        color: white;
    }

    .icon {
        margin: 0 -.5rem;
    }
}
</style>
