import ApiService from '@/services/api.service'
import TokenService from '@/services/storage.service'
import { parseJwt } from '@/utils'
import i18n, { getBrowserLanguageOrDefault } from '@/config/i18n'

const actionsObj = {
    async login({ commit, dispatch }, credentials) {
        commit('setError', null)
        commit('setLoading', true)
        try {
            const response = await ApiService.post('token/', credentials)
            commit('setTokens', response.data)
            TokenService.saveTokens(response.data)
            ApiService.enableAuth()
            dispatch('fetchUser')
        } catch (e) {
            commit('setError', e.response.data.detail)
        } finally {
            commit('setLoading', false)
        }
    },

    async refresh({ commit, dispatch, state }) {
        if (!state.refresh) {
            console.warn('Missing refresh token.')
            await dispatch('logout')
            return
        }

        commit('setLoading', true)
        try {
            const response = await ApiService.post('token/refresh/', { refresh: state.refresh })
            commit('setTokens', {
                access: response.data.access,
                refresh: state.refresh
            })
            TokenService.saveTokens({
                access: response.data.access,
                refresh: state.refresh
            })
            ApiService.enableAuth()
            await dispatch('checkAuth')
            dispatch('fetchUser')
        } catch (e) {
            console.error(e.message)
        } finally {
            commit('setLoading', false)
        }
    },

    logout({ commit }) {
        commit('setTokens', {
            access: null,
            refresh: null
        })
        TokenService.removeTokens()
        ApiService.disableAuth()
        i18n.locale = getBrowserLanguageOrDefault()
        ApiService.setDefaultHeader('Accept-Language', i18n.locale)
        window.location = '/'
    },

    async fetchUser({ commit }) {
        try {
            const response = await ApiService.get('user/user/self/')
            commit('setUser', response.data)
        } catch (e) {
            console.error(e.message)
        }
    },

    /**
     * Checks if the user is logged in, the tokens are not expired and performs a
     * token refresh if needed/possible
     */
    async checkAuth({ dispatch, commit }) {
        const { accessExpiration, refreshExpiration } = TokenService.getExpirationTimes()
        const { access, refresh } = TokenService.getTokens()
        const now = new Date()

        if (!access || !refresh) return

        // make sure locale is set
        const decodedAccess = parseJwt(access)
        if (decodedAccess.language) {
            i18n.locale = String(decodedAccess.language).toLowerCase()
            ApiService.setDefaultHeader('Accept-Language', i18n.locale)
        }
        if (decodedAccess.company_category)
            commit('setCompanyCategory', decodedAccess.company_category.toUpperCase())
        commit('setLastLogin', decodedAccess.last_login)

        if (accessExpiration > now)
            return ApiService.enableAuth()

        if (accessExpiration - now < 2 * 60 * 1000) { // Token expiring within 2 minutes
            if (refreshExpiration < now)
                return await dispatch('logout') // Refresh has expired
            await dispatch('refresh')
        }
    }
}

const stateObj = {
    access: null,
    refresh: null,
    loading: false,
    error: null,
    user: {},
    company_category: null,
    last_login: null
}

const mutationsObj = {
    setTokens(state, { access, refresh }) {
        state.access = access
        state.refresh = refresh
    },
    setError(state, message) {
        state.error = message
    },
    setLoading(state, enabled) {
        state.loading = enabled
    },
    setUser: (state, user) => state.user = user,
    setCompanyCategory: (state, category) => state.company_category = category,
    setLastLogin: (state, lastLogin) => state.last_login = lastLogin
}

const gettersObj = {
    isLoggedIn: state => !!state.access,
    isFirstLogin: state => state.user && state.last_login === null
}

export default {
    namespaced: true,
    actions: actionsObj,
    state: stateObj,
    mutations: mutationsObj,
    getters: gettersObj
}
