import Vue from 'vue'
import gDatepicker from '@/components/formfields/gDatepicker'
import gTimepicker from '@/components/formfields/gTimepicker'
import gDatetimepicker from '@/components/formfields/gDatetimepicker'
import gSelect from '@/components/formfields/gSelect'
import gAutocomplete from '@/components/formfields/gAutocomplete'
import gNullableBoolean from '@/components/formfields/gNullableBoolean'
import gInputField from '@/components/formfields/gInputField'
import gCheckboxField from '@/components/formfields/gCheckboxField'

Vue.component('GDatepicker', gDatepicker)
Vue.component('GTimepicker', gTimepicker)
Vue.component('GDatetimepicker', gDatetimepicker)
Vue.component('GSelect', gSelect)
Vue.component('GAutocomplete', gAutocomplete)
Vue.component('GNullableBoolean', gNullableBoolean)
Vue.component('GInputField', gInputField)
Vue.component('GCheckboxField', gCheckboxField)
