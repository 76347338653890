import ApiService from '@/services/api.service'

const DOCUMENT_ENDPOINT = 'documents/document/'

const actions = {
    async fetch({ commit }) {
        commit('setError', null)
        commit('setLoading', true)
        try {
            const response = await ApiService.get(DOCUMENT_ENDPOINT)
            commit('setDocuments', response.data)
        } catch (e) {
            commit('setError', e.response.data)
        } finally {
            commit('setLoading', false)
        }
    },
    async save({ commit, dispatch }, { fileName, file }) {
        commit('setError', null)
        commit('setLoading', true)
        const data = { file_name: fileName, file }
        try {
            await ApiService.post(DOCUMENT_ENDPOINT, data)
            dispatch('fetch')
        } catch (e) {
            commit('setError', e.response.data)
            commit('setLoading', false)
            throw(e)
        }
    },
    async remove({ commit, dispatch }, document) {
        commit('setError', null)
        commit('setLoading', true)
        try {
            await ApiService.delete(`${DOCUMENT_ENDPOINT}${document.id}`)
            dispatch('fetch')
        } catch (e) {
            commit('setError', e.response.data)
            commit('setLoading', false)
            throw(e)
        }
    },
    async getSuggestedFilename(context, fileName) {
        const response = await ApiService.get(
            `${DOCUMENT_ENDPOINT}suggested_filename/`,
            { params: { file_name: fileName } }
        )
        return response.data
    }
}

const state = {
    documents: [],
    loading: false,
    error: null
}

const mutations = {
    setDocuments(state, documents) {
        state.documents = documents
    },
    setError(state, message) {
        state.error = message
    },
    setLoading(state, enabled) {
        state.loading = enabled
    }
}

const getters = {}

export default {
    namespaced: true,
    actions: actions,
    state: state,
    mutations: mutations,
    getters: getters
}
