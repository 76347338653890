<template>
    <b-timepicker v-model="model"
                  :editable="true"
                  :time-formatter="timeFormatter"
                  hour-format="24"
                  style="min-width: 130px"
                  placeholder="12:00"
                  type="text"
    />
</template>

<script>
    import dayjs from 'dayjs'
    import { DISPLAY_TIME_FORMAT, DURATION_FORMAT, TIME_FORMAT } from '@/constants'

    export default {
        name: 'GTimePicker',
        props: {
            value: { type: [ String, Date ], default: null },
            duration: { type: Boolean, default: false }
        },
        computed: {
            format() {
                return this.duration ? DURATION_FORMAT : TIME_FORMAT
            },
            model: {
                get() {
                    return this.value ? dayjs(this.value, this.format).toDate() : null
                },
                set(value) {
                    let output = null
                    if (value) try {
                        output = dayjs(value).format(this.format)
                    } catch {
                        // pass
                    }
                    this.$emit('input', output)
                }
            }
        },
        methods: { timeFormatter: (value) => dayjs(value).format(DISPLAY_TIME_FORMAT) }
    }
</script>
