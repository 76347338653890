<template>
    <b-navbar spaced class="main-navigation" fixed-top>
        <template slot="brand">
            <b-navbar-item href="/">
                <img alt="Gastrosuisse"
                     src="~@/assets/images/visitor-logo.png"
                     srcset="~@/assets/images/visitor-logo@2x.png 2x, ~@/assets/images/visitor-logo@3x.png 3x">
            </b-navbar-item>
        </template>

        <template slot="start">
            <b-navbar-item
                v-if="!hasRole('display')"
                :to="{name:'forms'}"
                tag="router-link"
                data-v-step="0">
                {{ $t('Forms') }}
            </b-navbar-item>
            <b-navbar-item
                :to="{name:'archives'}"
                tag="router-link"
                data-v-step="1">
                {{ $t('Archive') }}
            </b-navbar-item>
            <b-navbar-item
                v-if="!hasRole('display')"
                :to="{name:'documents'}"
                tag="router-link"
                data-v-step="2">
                {{ $t('Documents') }}
            </b-navbar-item>
            <b-navbar-item
                v-if="!hasRole('display')"
                :to="{name:'settings'}"
                tag="router-link"
                data-v-step="3">
                {{ $t('Settings') }}
            </b-navbar-item>
        </template>

        <template slot="end">
            <b-dropdown position="is-bottom-left" trap-focus data-v-step="5"
                        style="border-radius: 4px;">
                <a slot="trigger" class="navbar-item" role="button">
                    <span class="is-hidden-desktop" style="margin-right: 0.5rem; font-size: .9rem">
                        {{ $t('Manual') }}
                    </span>
                    <QuestionIcon class="question-icon" />
                </a>
                <b-dropdown-item has-link aria-role="menuitem">
                    <a target="_blank" :href="manualURL">
                        {{ $t('Manual') }}
                    </a>
                </b-dropdown-item>
                <b-dropdown-item v-if="hasRole('administrator')" has-link aria-role="menuitem">
                    <a :href="$router.resolve({name: 'forms', query: { onboarding: true, }, }).href">
                        {{ $t('Restart Onboarding') }}
                    </a>
                </b-dropdown-item>
            </b-dropdown>

            <b-dropdown position="is-bottom-left" trap-focus data-v-step="4"
                        style="border-radius: 4px;">
                <a slot="trigger" class="navbar-item" role="button">
                    <span style="margin-right: 0.5rem;" class="navbar-item--user">
                        {{ user.first_name }} {{ user.last_name }}
                    </span>
                    <AvatarIcon class="avatar-icon" />
                    <ArrowDownIcon class="arrow-down-icon" />
                </a>
                <b-dropdown-item has-link aria-role="menuitem">
                    <router-link :to="{ name:'administration.profile' }">
                        {{ $t('My Profile') }}
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item has-link aria-role="menuitem">
                    <router-link :to="{ name:'administration.business' }">
                        {{ $t('My Business') }}
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item v-if="hasRole('administrator')" has-link aria-role="menuitem">
                    <router-link :to="{ name:'administration.subscription' }">
                        {{ $t('My Subscription') }}
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item v-if="hasRole('administrator')" has-link aria-role="menuitem">
                    <router-link :to="{ name:'administration.users' }">
                        {{ $t('User management') }}
                    </router-link>
                </b-dropdown-item>
                <hr class="dropdown-divider">
                <b-dropdown-item has-link aria-role="menuitem">
                    <a href="" @click.prevent="logout">{{ $t('Sign out') }}</a>
                </b-dropdown-item>
            </b-dropdown>
        </template>
    </b-navbar>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex'
    import AvatarIcon from '@/components/icons/Avatar'
    import QuestionIcon from '@/components/icons/Question'
    import ArrowDownIcon from '@/components/icons/ArrowDown'
    import { hasRole } from '@/modules/Auth/helpers'

    export default {
        name: 'Nav',
        components: {
            AvatarIcon,
            QuestionIcon,
            ArrowDownIcon
        },
        computed: {
            ...mapGetters('auth', [ 'isLoggedIn' ]),
            ...mapState('auth', [ 'user' ]),
            manualURL() {
                return this.$i18n.locale === 'de'
                    ? '/Manual_Hygienecontrol_D_V1.1.pdf'
                    : '/Manual_Hygienecontrol_F_V1.1.pdf'
            }
        },
        methods: {
            ...mapActions('auth', [ 'logout' ]),
            hasRole
        }
    }
</script>

<style lang="scss">
    @import "~@/assets/vars";

    nav.navbar.main-navigation {
        background-color: $white;
        height: 84px;

        .navbar-item {
            margin-left: .5rem;
            margin-right: .5rem;
            letter-spacing: 0.05em;
            color: $black;
            font-size: 14px;

            &.is-active {
                color: $red;
            }

            &--user {
                font-size: 16px;
            }
        }

        .navbar-start {
            margin-left: auto;
        }

        .navbar-end {
            margin-left: initial;
        }

        .navbar-start a.navbar-item.is-active {
            position: relative;

            @include from($desktop) {
                &:before {
                    content: '';
                    opacity: 1;
                    background: transparent;
                    border-bottom: solid 4px $red;
                    width: 100%;
                    height: 0;
                    position: absolute;
                    bottom: -27px;
                    left: 0;
                    z-index: 3;
                }
            }
        }

        .question-icon {
            width: 24px;
            display: inline-block;
            vertical-align: middle;
            color: $grey;

            &:hover {
                color: $black;
            }
        }

        .avatar-icon {
            width: 40px;
            display: inline-block;
            vertical-align: middle;
            @include until($desktop) {
                width: 1.2rem;
            }
        }

        .arrow-down-icon {
            margin-left: 5px;
        }
    }
</style>
