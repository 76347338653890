import ApiService from '../../services/api.service'

export const BUSINESS_ENDPOINT = 'companies/company/'

const actions = {
    async fetchBusinesses({ commit }) {
        commit('setError', {
            type: 'businesses',
            'error': null
        })
        commit('setLoading', {
            type: 'businesses',
            isLoading: true
        })

        try {
            const response = await ApiService.get(BUSINESS_ENDPOINT)
            commit('setBusinessesItems', response.data)
        } catch (e) {
            commit('setError', {
                type: 'businesses',
                'error': e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'businesses',
                isLoading: false
            })
        }
    },
    async saveBusiness({ commit, dispatch }, business) {
        commit('setError', {
            type: 'businesses',
            'error': null
        })
        commit('setLoading', {
            type: 'businesses',
            isLoading: true
        })

        try {
            await ApiService.put(`${BUSINESS_ENDPOINT}${business.id}/`, business)
            await dispatch('auth/refresh', null, { root: true })
        } catch (e) {
            commit('setError', {
                type: 'businesses',
                'error': e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'businesses',
                isLoading: false
            })
            window.location.reload()
        }
    }
}


const state = {
    businesses: {
        items: [],
        loading: false,
        error: null
    }
}

const mutations = {
    setBusinessesItems(state, items) {
        state.businesses = {
            ...state.businesses,
            items
        }
    },
    setData(state, { data, type }) {
        const old = state[type]
        state[type] = {
            ...old,
            data
        }
    },
    setLoading(state, { isLoading, type }) {
        state[type].loading = isLoading
    },
    setError(state, { error, type }) {
        state[type].error = error
    }
}

export default {
    namespaced: true,
    actions: actions,
    state: state,
    mutations: mutations
}
