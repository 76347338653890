module.exports = [
    {
        path: '/archives',
        name: 'archives',
        component: () => import('./Archives'),
        redirect: { name: 'archives.list' },
        children: [
            {
                path: 'list',
                name: 'archives.list',
                component: () => import('./ArchiveList')
            }
        ]
    }
]
