import ApiService from '@/services/api.service'

const ENDPOINT = 'reminders/'

const actions = {
    async fetch({ commit }, formType) {
        commit('setError', null)
        commit('setLoading', true)
        try {
            return await ApiService.get(ENDPOINT + formType + '/')
        } catch (e) {
            commit('setError', e.response.data)
        } finally {
            commit('setLoading', false)
        }
    },
    async save({ commit, dispatch }, record) {
        try {
            const response = await record.id
                ? ApiService.put(ENDPOINT + record.form_type + '/', record)
                : ApiService.post(ENDPOINT, record)
            dispatch('forms/fetch', { subtypes: true, reminders: true }, { root: true })

            return response
        } catch (e) {
            commit('setError', e.response.data)
        } finally {
            commit('setLoading', false)
        }
    },
    async delete({ commit, dispatch }, record) {
        try {
            const response = await ApiService.delete(ENDPOINT + record.form_type + '/')

            dispatch('forms/fetch', { subtypes: true, reminders: true }, { root: true })

            return response
        } catch (e) {
            commit('setError', e.response.data)
        } finally {
            commit('setLoading', false)
        }
    }
}

const state = {
    loading: false,
    error: null
}

const mutations = {
    setError(state, message) {
        state.error = message
    },
    setLoading(state, enabled) {
        state.loading = enabled
    }
}

const getters = {}

export default {
    namespaced: true,
    actions: actions,
    state: state,
    mutations: mutations,
    getters: getters
}
