import { arrayize } from '@/utils'
import store from '@/config/store'


export const hasRole = role => {
    if (!role)
        return true

    const state = store.state.auth

    return state.user && state.user.role ? arrayize(role).includes(state.user.role) : false
}
