import ApiService from '@/services/api.service'
import { uuidv4 } from '@/utils'

const SECTIONS_ENDPOINT = 'settings/sections/'
const EMPLOYEE_ENDPOINT = 'settings/employee/'
const SUPPLIER_ENDPOINT = 'settings/supplier/'
const DISPOSAL_STATION_ENDPOINT = 'settings/disposal-station/'
const INVENTORY_ENDPOINT = 'settings/inventory/'
const CUTTING_BOARD_COLOR_CONCEPT_ENDPOINT = 'settings/cutting-board-color-concept/'
const TEST_AND_MEASURING_DEVICE_ENDPOINT = 'settings/test-and-measuring-device/'
const STORAGE_LOCATION_ENDPOINT = 'settings/storage-location/'
const CLEANING_LOCATION_ENDPOINT = 'settings/cleaning-location/'
const CLEANING_AGENT_ENDPOINT = 'settings/cleaning-agent/'
const CLEANING_COLOR_CONCEPT_ENDPOINT = 'settings/cleaning-color-concept/'

const mapEndpoints = {
    'employee': EMPLOYEE_ENDPOINT,
    'supplier': SUPPLIER_ENDPOINT,
    'disposalStation': DISPOSAL_STATION_ENDPOINT,
    'inventory': INVENTORY_ENDPOINT,
    'cuttingBoardColorConcept': CUTTING_BOARD_COLOR_CONCEPT_ENDPOINT,
    'testAndMeasuringDevice': TEST_AND_MEASURING_DEVICE_ENDPOINT,
    'storageLocation': STORAGE_LOCATION_ENDPOINT,
    'cleaningLocation': CLEANING_LOCATION_ENDPOINT,
    'cleaningAgent': CLEANING_AGENT_ENDPOINT,
    'cleaningColorConcept': CLEANING_COLOR_CONCEPT_ENDPOINT
}


const actions = {
    async fetchSections({ commit }) {
        commit('setError', { type: 'sections', error: null })
        commit('setLoading', { type: 'sections', isLoading: true })

        return ApiService.get(SECTIONS_ENDPOINT)
            .then(response => {
                commit('setSections', response.data)
            }).catch(e => {
                commit('setError', { type: 'sections', 'error': e.response.data })
            }).finally(() => {
                commit('setLoading', { type: 'sections', isLoading: false })
            })
    },
    async saveSectionItem({ commit, dispatch }, { section, data }) {
        commit('setError', { type: section, error: null })
        commit('setLoading', { type: section, isLoading: true })

        if (!Object.prototype.hasOwnProperty.call(mapEndpoints, section))
            throw new Error('Section does not exist or endpoint is not mapped.')

        let endpoint = mapEndpoints[ section ]
        let method = ApiService.post

        if (data.id) {
            endpoint += `${ data.id }/`
            method = ApiService.put
        }

        return method(endpoint, data)
            .then(response => {
                commit('setSections', response.data)
            }).catch(e => {
                if (Object.prototype.hasOwnProperty.call(e, 'response')) {
                    const data = e.response.data
                    const message = data.detail || data.message || data.__all__ || data
                    commit('setError', {
                        type: section,
                        'error': message
                    })
                    return Promise.reject(e)
                }
            }).finally(() => {
                commit('setLoading', { type: section, isLoading: false })
            }).then(() => dispatch('fetchSections'))
    },
    async removeSectionItem({ commit, dispatch }, { section, id }) {
        commit('setError', { type: section, error: null })
        commit('setLoading', { type: section, isLoading: true })

        if (!Object.prototype.hasOwnProperty.call(mapEndpoints, section))
            throw new Error('Section does not exist or endpoint is not mapped.')

        let endpoint = mapEndpoints[ section ]

        return ApiService.delete(`${ endpoint }${ id }/`)
            .then(response => {
                commit('setSections', response.data)
            }).catch(e => {
                if (Object.prototype.hasOwnProperty.call(e, 'response'))
                    commit('setError', { type: section, 'error': e.response.data })
            }).finally(() => {
                commit('setLoading', { type: section, isLoading: false })
            }).then(() => dispatch('fetchSections'))
    },
    async fetchEmployee({ commit }) {
        commit('setError', { type: 'employee', error: null })
        commit('setLoading', { type: 'employee', isLoading: true })

        try {
            const response = await ApiService.get(EMPLOYEE_ENDPOINT)
            commit('setData', {
                data: response.data,
                type: 'employee'
            })
        } catch (e) {
            commit('setError', {
                type: 'employee',
                error: e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'employee',
                isLoading: false
            })
        }
    },
    async saveEmployee({ commit }, list) {
        commit('setError', {
            type: 'employee',
            error: null
        })
        commit('setLoading', {
            type: 'employee',
            isLoading: true
        })

        try {
            const response = await ApiService.put(`${ EMPLOYEE_ENDPOINT }update-list/`, list)
            commit('setData', {
                type: 'employee',
                data: response.data
            })
        } catch (e) {
            commit('setError', {
                type: 'employee',
                error: e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'employee',
                isLoading: false
            })
        }
    },
    async fetchSupplier({ commit }) {
        commit('setError', { type: 'supplier', error: null })
        commit('setLoading', { type: 'supplier', isLoading: true })

        try {
            const response = await ApiService.get(SUPPLIER_ENDPOINT)
            commit('setSupplierData', { data: response.data })
        } catch (e) {
            commit('setError', {
                type: 'supplier',
                error: e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'supplier',
                isLoading: false
            })
        }
    },
    async saveSupplier({ commit }, list) {
        commit('setError', {
            type: 'supplier',
            error: null
        })
        commit('setLoading', {
            type: 'supplier',
            isLoading: true
        })

        try {
            const response = await ApiService.put(`${ SUPPLIER_ENDPOINT }update-list/`, list)
            commit('setSupplierData', { data: response.data })
        } catch (e) {
            commit('setError', {
                type: 'supplier',
                error: e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'supplier',
                isLoading: false
            })
        }
    },
    async fetchDisposalStation({ commit }) {
        commit('setError', { type: 'disposalStation', error: null })
        commit('setLoading', { type: 'disposalStation', isLoading: true })

        try {
            const response = await ApiService.get(DISPOSAL_STATION_ENDPOINT)
            commit('setDisposalStationData', { data: response.data })
        } catch (e) {
            commit('setError', {
                type: 'disposalStation',
                error: e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'disposalStation',
                isLoading: false
            })
        }
    },
    async saveDisposalStation({ commit }, list) {
        commit('setError', {
            type: 'disposalStation',
            error: null
        })
        commit('setLoading', {
            type: 'disposalStation',
            isLoading: true
        })

        try {
            const response = await ApiService.put(`${ DISPOSAL_STATION_ENDPOINT }update-list/`, list)
            commit('setDisposalStationData', { data: response.data })
        } catch (e) {
            commit('setError', {
                type: 'disposalStation',
                error: e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'disposalStation',
                isLoading: false
            })
        }
    },
    async fetchInventory({ commit }) {
        commit('setError', {
            type: 'inventory',
            'error': null
        })
        commit('setLoading', {
            type: 'inventory',
            isLoading: true
        })

        return ApiService.get(INVENTORY_ENDPOINT)
            .then(response => {
                commit('setInventoryItems', response.data)
            }).catch(e => {
                commit('setError', {
                    type: 'inventory',
                    'error': e.response.data
                })
            }).finally(() => {
                commit('setLoading', {
                    type: 'inventory',
                    isLoading: false
                })
            })
    },
    async saveInventory({ commit, state }, { data } = {}) {
        commit('setError', { type: 'inventory', 'error': null })
        commit('setLoading', { type: 'inventory', isLoading: true })

        const _data = data || state.inventory.data

        return ApiService.put(`${ INVENTORY_ENDPOINT }update-list/`, _data)
            .then(response => {
                commit('setInventoryItems', response.data)
            }).catch(e => {
                commit('setError', { type: 'inventory', 'error': e.response.data })
            }).finally(() => {
                commit('setLoading', { type: 'inventory', isLoading: false })
            })
    },
    async removeInventoryItem({ state, dispatch }, { uuid }) {
        const data = state.inventory.data.filter(item => item.uuid !== uuid)

        return dispatch('saveInventory', { data })
    },
    async fetchCuttingBoardColorConcept({ commit }) {
        commit('setError', {
            type: 'cuttingBoardColorConcept',
            'error': null
        })
        commit('setLoading', {
            type: 'cuttingBoardColorConcept',
            isLoading: true
        })

        return ApiService.get(CUTTING_BOARD_COLOR_CONCEPT_ENDPOINT)
            .then(response => {
                commit('setCuttingBoardColorConceptItems', response.data)
            }).catch(e => {
                commit('setError', {
                    type: 'cuttingBoardColorConcept',
                    'error': e.response.data
                })
            }).finally(() => {
                commit('setLoading', {
                    type: 'cuttingBoardColorConcept',
                    isLoading: false
                })
            })
    },
    async saveCuttingBoardColorConcept({ commit, state }, { data } = {}) {
        commit('setError', { type: 'cuttingBoardColorConcept', 'error': null })
        commit('setLoading', { type: 'cuttingBoardColorConcept', isLoading: true })

        const _data = data || state.cuttingBoardColorConcept.data

        return ApiService.put(`${ CUTTING_BOARD_COLOR_CONCEPT_ENDPOINT }update-list/`, _data)
            .then(response => {
                commit('setCuttingBoardColorConceptItems', response.data)
            }).catch(e => {
                commit('setError', { type: 'cuttingBoardColorConcept', 'error': e.response.data })
            }).finally(() => {
                commit('setLoading', { type: 'cuttingBoardColorConcept', isLoading: false })

            })
    },
    async removeCuttingBoardColorConceptItem({ state, dispatch }, { uuid }) {
        const data = state.cuttingBoardColorConcept.data.filter(item => item.uuid !== uuid)

        return dispatch('saveCuttingBoardColorConcept', { data })
    },
    async fetchTestAndMeasuringDevice({ commit }) {
        commit('setError', {
            type: 'testAndMeasuringDevice',
            error: null
        })
        commit('setLoading', {
            type: 'testAndMeasuringDevice',
            isLoading: true
        })
        try {
            const response = await ApiService.get(TEST_AND_MEASURING_DEVICE_ENDPOINT)
            commit('setTestAndMeasuringDeviceData', { data: response.data })
        } catch (e) {
            commit('setError', {
                type: 'testAndMeasuringDevice',
                error: e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'v',
                isLoading: false
            })
        }
    },
    async saveTestAndMeasuringDevice({ commit }, list) {
        commit('setError', {
            type: 'testAndMeasuringDevice',
            error: null
        })
        commit('setLoading', {
            type: 'testAndMeasuringDevice',
            isLoading: true
        })

        try {
            const response = await ApiService.put(`${ TEST_AND_MEASURING_DEVICE_ENDPOINT }update-list/`, list)
            commit('setTestAndMeasuringDeviceData', { data: response.data })
        } catch (e) {
            commit('setError', {
                type: 'testAndMeasuringDevice',
                error: e.response.data
            })
        } finally {
            commit('setLoading', {
                type: 'testAndMeasuringDevice',
                isLoading: false
            })
        }
    },
    async fetchStorageLocation({ commit }) {
        commit('setError', {
            type: 'storageLocation',
            'error': null
        })
        commit('setLoading', {
            type: 'storageLocation',
            isLoading: true
        })

        return ApiService.get(STORAGE_LOCATION_ENDPOINT)
            .then(response => {
                commit('setStorageLocationItems', response.data)
            }).catch(e => {
                commit('setError', {
                    type: 'storageLocation',
                    'error': e.response.data
                })
            }).finally(() => {
                commit('setLoading', {
                    type: 'storageLocation',
                    isLoading: false
                })
            })
    },
    async saveStorageLocation({ commit, state }, { data } = {}) {
        commit('setError', { type: 'storageLocation', 'error': null })
        commit('setLoading', { type: 'storageLocation', isLoading: true })

        const _data = data || state.storageLocation.data

        return ApiService.put(`${ STORAGE_LOCATION_ENDPOINT }update-list/`, _data)
            .then(response => {
                commit('setStorageLocationItems', response.data)
            }).catch(e => {
                commit('setError', { type: 'storageLocation', 'error': e.response.data })
            }).finally(() => {
                commit('setLoading', { type: 'storageLocation', isLoading: false })
            })
    },
    async removeStorageLocationItem({ state, dispatch }, { uuid }) {
        const data = state.storageLocation.data.filter(item => item.uuid !== uuid)

        return dispatch('saveStorageLocation', { data })
    },
    async fetchCleaningLocation({ commit }) {
        commit('setError', {
            type: 'cleaningLocation',
            'error': null
        })
        commit('setLoading', {
            type: 'cleaningLocation',
            isLoading: true
        })

        return ApiService.get(CLEANING_LOCATION_ENDPOINT)
            .then(response => {
                commit('setCleaningLocationItems', response.data.map(item => ({
                    ...item,
                    uuid: uuidv4()
                })))
            }).catch(e => {
                commit('setError', {
                    type: 'cleaningLocation',
                    'error': e.response.data
                })
            }).finally(() => {
                commit('setLoading', {
                    type: 'cleaningLocation',
                    isLoading: false
                })
            })
    },
    async saveCleaningLocation({ commit, state }, { items } = {}) {
        commit('setError', {
            type: 'cleaningLocation',
            'error': null
        })
        commit('setLoading', {
            type: 'cleaningLocation',
            isLoading: true
        })

        const data = items || state.cleaningLocation.items

        return ApiService.put(CLEANING_LOCATION_ENDPOINT + 'update-list/', data)
            .then(response => {
                commit('setCleaningLocationItems', response.data)
            }).catch(e => {
                commit('setError', {
                    type: 'cleaningLocation',
                    'error': e.response.data
                })
            }).finally(() => {
                commit('setLoading', {
                    type: 'cleaningLocation',
                    isLoading: false
                })
            })
    },
    async removeCleaningLocationItem({ dispatch, state }, { uuid }) {
        const items = state.cleaningLocation.items.filter(item => item.uuid !== uuid)

        return dispatch('saveCleaningLocation', { items })
    },
    async fetchCleaningAgent({ commit }) {
        commit('setError', {
            type: 'cleaningAgent',
            'error': null
        })
        commit('setLoading', {
            type: 'cleaningAgent',
            isLoading: true
        })

        return ApiService.get(CLEANING_AGENT_ENDPOINT)
            .then(response => {
                commit('setCleaningAgentItems', response.data)
            }).catch(e => {
                commit('setError', {
                    type: 'cleaningAgent',
                    'error': e.response.data
                })
            }).finally(() => {
                commit('setLoading', {
                    type: 'cleaningAgent',
                    isLoading: false
                })
            })
    },
    async saveCleaningAgent({ commit, state }, { items } = {}) {
        commit('setError', {
            type: 'cleaningAgent',
            'error': null
        })
        commit('setLoading', {
            type: 'cleaningAgent',
            isLoading: true
        })

        const data = items || state.cleaningAgent.items

        return ApiService.put(CLEANING_AGENT_ENDPOINT + 'update-list/', data)
            .then(response => {
                commit('setCleaningAgentItems', response.data)
            }).catch(e => {
                commit('setError', {
                    type: 'cleaningAgent',
                    'error': e.response.data
                })
            }).finally(() => {
                commit('setLoading', {
                    type: 'cleaningAgent',
                    isLoading: false
                })
            })
    },
    async removeCleaningAgentItem({ state, dispatch }, { uuid }) {
        const items = state.cleaningAgent.items.filter(item => item.uuid !== uuid)

        return dispatch('saveCleaningAgent', { items })
    },
    async fetchCleaningColorConcept({ commit }) {
        commit('setError', {
            type: 'cleaningColorConcept',
            'error': null
        })
        commit('setLoading', {
            type: 'cleaningColorConcept',
            isLoading: true
        })

        return ApiService.get(CLEANING_COLOR_CONCEPT_ENDPOINT)
            .then(response => {
                commit('setCleaningColorConceptItems', response.data)
            }).catch(e => {
                commit('setError', {
                    type: 'cleaningColorConcept',
                    'error': e.response.data
                })
            }).finally(() => {
                commit('setLoading', {
                    type: 'cleaningColorConcept',
                    isLoading: false
                })
            })
    },
    async saveCleaningColorConcept({ commit, state }, { items } = {}) {
        commit('setError', {
            type: 'cleaningColorConcept',
            'error': null
        })
        commit('setLoading', {
            type: 'cleaningColorConcept',
            isLoading: true
        })

        const data = items || state.cleaningColorConcept.items

        return ApiService.put(CLEANING_COLOR_CONCEPT_ENDPOINT + 'update-list/', data)
            .then(response => {
                commit('setCleaningColorConceptItems', response.data)
            }).catch(e => {
                commit('setError', {
                    type: 'cleaningColorConcept',
                    'error': e.response.data
                })
            }).finally(() => {
                commit('setLoading', {
                    type: 'cleaningColorConcept',
                    isLoading: false
                })
            })
    },
    async removeCleaningColorConceptItem({ state, dispatch }, { uuid }) {
        const items = state.cleaningColorConcept.items.filter(item => item.uuid !== uuid)

        return dispatch('saveCleaningColorConcept', { items })
    }
}


const state = {
    sections: {
        data: [],
        loading: false,
        error: null
    },
    inventory: {
        data: [],
        loading: false,
        error: null
    },
    employee: {
        data: [],
        loading: false,
        error: null
    },
    cuttingBoardColorConcept: {
        data: [],
        loading: false,
        error: null
    },
    testAndMeasuringDevice: {
        data: [],
        loading: false,
        error: null
    },
    supplier: {
        data: [],
        loading: false,
        error: null
    },
    disposalStation: {
        data: [],
        loading: false,
        error: null
    },
    storageLocation: {
        data: [],
        loading: false,
        error: null
    },
    cleaningLocation: {
        data: [],
        loading: false,
        error: null
    },
    cleaningAgent: {
        data: [],
        loading: false,
        error: null
    },
    cleaningColorConcept: {
        data: [],
        loading: false,
        error: null
    }
}

function lowerFirst(string) {
    return string.charAt(0).toLowerCase() + string.slice(1)
}

const mutations = {
    setSections(state, sections) {
        for (const section of sections) {
            const _section = lowerFirst(section.type)
            if (Object.prototype.hasOwnProperty.call(state, _section)) {
                state[ _section ].data = section.data
                state[ _section ].introText = section.intro_text
                state[ _section ].categories = section.categories
            }
        }
    },
    setInventoryItems(state, data) {
        state.inventory.data = data.map(item => ({ ...item, uuid: uuidv4() }))
    },
    addInventoryItem(state, item) {
        if (!item.uuid)
            item.uuid = uuidv4()

        state.inventory.data.push(item)
    },
    setCuttingBoardColorConceptItems(state, data) {
        state.cuttingBoardColorConcept.data = data.map(item => ({ ...item, uuid: uuidv4() }))
    },
    addCuttingBoardColorConceptItem(state, item) {
        if (!item.uuid)
            item.uuid = uuidv4()


        state.cuttingBoardColorConcept.data.push(item)
    },
    setData(state, { data, type }) {
        const old = state[ type ]
        state[ type ] = {
            ...old,
            data
        }
    },
    setLoading(state, { isLoading, type }) {
        state[ type ].loading = isLoading
    },
    setError(state, { error, type }) {
        state[ type ].error = error
    },
    setTestAndMeasuringDeviceData(state, { data }) {
        const old = state.testAndMeasuringDevice
        state.testAndMeasuringDevice = {
            ...old,
            data
        }
    },
    setSupplierData(state, { data }) {
        const old = state.supplier
        state.supplier = {
            ...old,
            data
        }
    },
    setDisposalStationData(state, { data }) {
        const old = state.disposalStation
        state.disposalStation = {
            ...old,
            data
        }
    },
    setStorageLocationItems(state, data) {
        state.storageLocation.data = data.map(item => ({ ...item, uuid: uuidv4() }))
    },
    addStorageLocationItem(state, item) {
        if (!item.uuid)
            item.uuid = uuidv4()

        state.storageLocation.data.push(item)
    },
    setCleaningLocationItems(state, items) {
        state.cleaningLocation.items = items.map(item => ({
            ...item,
            uuid: uuidv4()
        }))
    },
    addCleaningLocationItem(state, item) {
        if (!item.uuid)
            item.uuid = uuidv4()


        state.cleaningLocation.items.push(item)
    },
    setCleaningAgentItems(state, items) {
        state.cleaningAgent.items = items.map(item => ({
            ...item,
            uuid: uuidv4()
        }))
    },
    addCleaningAgentItem(state, item) {
        if (!item.uuid)
            item.uuid = uuidv4()


        state.cleaningAgent.items.push(item)
    },
    setCleaningColorConceptItems(state, items) {
        state.cleaningColorConcept.items = items.map(item => ({
            ...item,
            uuid: uuidv4()
        }))
    },
    addCleaningColorConceptItem(state, item) {
        if (!item.uuid)
            item.uuid = uuidv4()


        state.cleaningColorConcept.items.push(item)
    }
}

const getters = {}

export default {
    namespaced: true,
    actions: actions,
    state: state,
    mutations: mutations,
    getters: getters
}
