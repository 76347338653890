module.exports = [
    {
        path: '/login',
        name: 'login',
        meta: { auth: false },
        component: () => import('./Login')
    },
    {
        path: '/user/activate',
        name: 'user-activation',
        component: () => import('./UserActivation'),
        meta: { auth: false }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('./ForgotPassword'),
        meta: { auth: false }
    }
]
