import Vue from 'vue'

import dayjs from 'dayjs'
import { DISPLAY_DATE_FORMAT } from '@/constants'
import { filesize } from '@/utils'

Vue.filter('displayDate', (stringDate, format = DISPLAY_DATE_FORMAT) => dayjs(stringDate).format(format))


Vue.filter('diskQuota', ({ used, total }) => `${ filesize(used) } / ${ filesize(total) }`)
