import { parseJwt } from '../utils'

const TOKEN_KEY = 'access-token'
const TOKEN_EXPIRATION_KEY = `${ TOKEN_KEY }-expiration`

const REFRESH_TOKEN_KEY = 'refresh-token'
const REFRESH_TOKEN_EXPIRATION_KEY = `${ REFRESH_TOKEN_KEY }-expiration`
import i18n from '@/config/i18n'

/**
 * Manage how Access Tokens are being stored and retrieved from storage.
 * */
const TokenService = {
    getTokens() {
        return {
            access: localStorage.getItem(TOKEN_KEY),
            refresh: localStorage.getItem(REFRESH_TOKEN_KEY)
        }
    },

    /**
     *  Persists the application tokens to localStorage. It also stores their expiration dates.
     *
     * @param {Object} args
     * @param {String} args.access The bearer token used as a credential for requests to the api
     * @param {String} args.refresh The token to use in order to refresh the access token.
     *                              This token as an expiration date as well
     */
    saveTokens({ access, refresh }) {
        localStorage.setItem(TOKEN_KEY, access)
        localStorage.setItem(REFRESH_TOKEN_KEY, refresh)

        const decodedAccess = parseJwt(access)
        const decodedRefresh = parseJwt(refresh)

        if (decodedAccess.language)
            i18n.locale = String(decodedAccess.language).toLowerCase()

        this.saveExpirationTimes({
            accessExpiration: new Date(decodedAccess.exp),
            refreshExpiration: new Date(decodedRefresh.exp)
        })
    },

    /**
     * Removes application tokens from localStorage.
     */
    removeTokens() {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
        this.removeExpirationTimes()
    },

    /**
     * This method stores in localStorage the expiration dates for both refresh and access tokens.
     * These are meant to determine if a token is still valid, or if they are stale and the user
     * should sign in again.
     *
     * @private
     *
     * @param {Object} args
     * @param {Date} args.accessExpiration The date representing the access token expiration
     * @param {Date} args.refreshExpiration The date representing the refresh token expiration
     */
    saveExpirationTimes({ accessExpiration, refreshExpiration }) {
        localStorage.setItem(TOKEN_EXPIRATION_KEY, accessExpiration.getTime())
        localStorage.setItem(REFRESH_TOKEN_EXPIRATION_KEY, refreshExpiration.getTime())
    },

    getExpirationTimes() {
        let accessExpiration = localStorage.getItem(TOKEN_EXPIRATION_KEY)
        let refreshExpiration = localStorage.getItem(REFRESH_TOKEN_EXPIRATION_KEY)

        accessExpiration = accessExpiration ? new Date(accessExpiration * 1000) : null
        refreshExpiration = refreshExpiration ? new Date(refreshExpiration * 1000) : null
        return { accessExpiration, refreshExpiration }
    },

    /**
     * Removes application tokens from localStorage
     *
     * @private
     */
    removeExpirationTimes() {
        localStorage.removeItem(TOKEN_EXPIRATION_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    }
}

export default TokenService
