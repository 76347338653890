import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import { DEFAULT_LANG, LANGUAGE_CODES } from '../constants'

Vue.use(VueI18n)

// load at run-time
axios.get(`/static/locale/en.json`, { headers: { 'Cache-Control': 'no-cache' } })
    .then(response => i18n.setLocaleMessage(LANGUAGE_CODES.english, response.data))
axios.get(`/static/locale/de.json`, { headers: { 'Cache-Control': 'no-cache' } })
    .then(response => i18n.setLocaleMessage(LANGUAGE_CODES.german, response.data))
axios.get(`/static/locale/fr.json`, { headers: { 'Cache-Control': 'no-cache' } })
    .then(response => i18n.setLocaleMessage(LANGUAGE_CODES.french, response.data))

export const getBrowserLanguageOrDefault = () => {
    const browserLanguage = navigator.language || navigator.userLanguage
    const segments = browserLanguage.split('-')
    let language = DEFAULT_LANG

    if (segments.length) {
        const firstSegment = segments.reverse().pop()
        if (firstSegment === LANGUAGE_CODES.french)
            language = LANGUAGE_CODES.french
    }
    return language
}

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: getBrowserLanguageOrDefault(), // set locale
    silentTranslationWarn: true, //!__DEV__, // silence it in production
    fallbackLocale: DEFAULT_LANG
})

export default i18n

export const setDocumentLang = lang => document.documentElement.lang = lang
