<template>
    <div id="app">
        <template v-if="isLoggedIn">
            <Nav />
            <TrialAlertBox />
        </template>
        <router-view />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Nav from '@/components/TheNavigation'
    import TrialAlertBox from './components/TrialAlertBox'

    export default {
        name: 'App',
        components: { TrialAlertBox, Nav },
        computed: { ...mapGetters('auth', [ 'isLoggedIn' ]) }
    }
</script>
<style lang="scss" scoped>

</style>
