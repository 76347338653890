import Vue from 'vue'
import App from './App.vue'
import router from '@/config/router'
import store from '@/config/store'
import '@/config/validation'
import '@/components/formfields'
import '@/filters'
import Buefy from 'buefy'
import '@/config/http'
import './assets/base.scss'
import ApiService from '@/services/api.service'
import i18n, { setDocumentLang } from '@/config/i18n'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekday from 'dayjs/plugin/weekday'
import 'dayjs/locale/de-ch'
import PortalVue from 'portal-vue'
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.locale('de-ch')

Vue.use(Buefy, { defaultFirstDayOfWeek: 1 })
Vue.use(PortalVue)
Vue.use(VueTour)

Vue.config.productionTip = false
ApiService.init(process.env.VUE_APP_API_ROOT)

new Vue({
    i18n,
    router,
    store,
    watch: {
        '$i18n.locale': {
            immediate: true,
            handler(newLocale, oldLocale) {
                if (newLocale===oldLocale)
                    return

                setDocumentLang(newLocale)
            }
        }
    },
    async mounted() {
        await store.dispatch('auth/checkAuth') // check token & set locale

        const routeLang = new URLSearchParams(document.location.search).get('lang')
        if (routeLang)
            i18n.locale = routeLang.toLowerCase()
    },
    render: h => h(App)
}).$mount('#app')
