module.exports = [
    {
        path: '/documents',
        name: 'documents',
        component: () => import('./Documents'),
        redirect: { name: 'documents.list' },
        children: [
            {
                path: 'list',
                name: 'documents.list',
                component: () => import('./DocumentList')
            }
        ]
    }
]
